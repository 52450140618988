import { createStore } from "vuex";
import auth from "./auth";
import client from "./client";
import account from "./account";
import notification from "./notification";
import component from "./component";
import code from "./code";
import journal from "./journal";
import lang from "./lang";
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    client,
    code,
    account,
    notification,
    component,
    journal,
    lang,
  },
});
