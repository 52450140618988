import api from "../services/api";
import auth from "./auth";
export default {
  namespaced: true,
  state: {
    codes: [],
    myCodes: [],
    userOhadaCodes: [],
  },
  mutations: {
    STORE_OHADA_CODES(state, payload) {
      state.codes = payload;
    },
    SET_MY_CODES(state, payload) {
      state.myCodes = payload;
    },
    ADD_CODE(state, payload) {
      state.myCodes.unshift(payload);
    },
    ADD_USER_OHADA_CODE(state, payload) {
      state.userOhadaCodes.unshift(payload);
    },
    STORE_USER_OHADA_CODES(state, payload) {
      state.userOhadaCodes = payload;
    },
  },
  actions: {
    async fetchOhadaCodes(
      { commit },
      payload = { isPaginate: false, isSearch: false }
    ) {
      // var queryString =
      //   "/get/ohada-codes?" + payload.isPaginate &&
      //   `page=${payload.page}` + payload.isSearch &&
      //   `&q=${payload.searchTerm}`;
      var pageString = payload.isPaginate ? "page=" + payload.page : "";
      var searchstring = payload.isSearch ? "&$q=" + payload.searchTerm : "";
      var queryString = "/get/ohada-codes?" + pageString + "" + searchstring;

      let response = await api()
        .get(queryString)
        .then((res) => {
          if (payload.isPaginate == false || payload.isSearch == false) {
            commit("STORE_OHADA_CODES", res.data.data);
          } else {
            commit("STORE_OHADA_CODES", res.data.data.data);
          }
          return res;
        });
      return response;
    },
    async addEditCode({ commit }, payload) {
      let res;
      let parent_id = auth.state.user.id;
      payload.parent_id = parent_id;
      if (payload.mode === "create") {
        res = await api()
          .post("client-code/add", payload)
          .then((res) => {
            if (res.data.status === "success") {
              commit("ADD_CODE", payload);
            }
            return res;
          });
      } else if (payload.mode === "edit") {
        payload.prohada_code_id = payload.id;
        res = await api()
          .post("/client-code/update", payload)
          .then((res) => {
            return res;
          });
      }
      return res.data;
    },
    async addEditUserOhadaCode({ commit }, payload) {
      let res;
      let parent_id = auth.state.user.id;
      payload.parent_id = parent_id;
      if (payload.mode === "addUserOhada") {
        res = await api()
          .post("user-ohada-code/add", payload)
          .then((res) => {
            if (res.data.status === "success") {
              commit("ADD_USER_OHADA_CODE", payload);
            }
            return res;
          });
      } else if (payload.mode === "editUserOhada") {
        res = await api()
          .post("/user-ohada-code/update", payload)
          .then((res) => {
            return res;
          });
      }
      return res.data;
    },
    async deleteCode({ state }, payload) {
      state.myCodes = state.myCodes.filter((c) => c.id != payload);
      let res = await api().post("client-code/delete", {
        prohada_code_id: payload,
      });
      return res.data;
    },

    async deleteUserOhadaCode({ state }, payload) {
      state.userOhadaCodes = state.userOhadaCodes.filter(
        (c) => c.id != payload
      );
      let res = await api().post("user-ohada-code/delete", {
        user_ohada_code_id: payload,
      });
      return res.data;
    },

    async fetchMyCodes({ commit }) {
      let parent_id = auth.state.user.id;
      let res = await api()
        .post("/client-code/fetch", { parent_id: parent_id })
        .then((res) => {
          commit("SET_MY_CODES", res.data.data);
          return res.data;
        });
      return res.data;
    },
    async fetchUserOhadaCodes(
      { commit },
      payload = { isPaginate: false, isSearch: false }
    ) {
      // let parent_id = auth.state.user.id;
      var pageString = payload.isPaginate ? "page=" + payload.page : "";
      var searchstring = payload.isSearch ? "&$q=" + payload.searchTerm : "";
      var queryString =
        "/get/user-ohada-codes?" + pageString + "" + searchstring;

      let response = await api()
        .get(queryString)
        .then((res) => {
          if (payload.isPaginate == false || payload.isSearch == false) {
            commit("STORE_USER_OHADA_CODES", res.data.data);
          } else {
            commit("STORE_USER_OHADA_CODES", res.data.data.data);
          }
          return res;
        });
      return response;
    },
  },
};
