import axios from "axios";
import store from "../store/index";

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    headers: {
      Authorization: store.state.auth.token,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    },
  });
};
