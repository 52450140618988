<template>
  <keep-alive>
    <component :is="layout">
      <slot />
    </component>
  </keep-alive>
</template>

<script>
// import DefaultLayout from './Defa0ultLayout'
import { ref, markRaw, watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";
export default {
  name: "AppLayout",
  setup() {
    const route = useRoute();
    const layout = ref();
    const getLayout = async (lyt) => {
      const c = await import(`@/layouts/${lyt}.vue`);
      return c.default;
    };
    watch(
      () => route.meta,
      async (meta) => {
        try {
          layout.value = markRaw(await getLayout(meta.layout));
        } catch (e) {
          layout.value = markRaw(await getLayout("DefaultLayout"));
        }
      }
    );
    return { layout };
  },

  // data: () => ({
  //   layout: markRaw(DefaultLayout)
  // }),
  // watch: {
  //   $route: {
  //     immid: true,
  //     async handler(route) {
  //       try {
  //         const component = await import(`@/layouts/${route.meta.layout}.vue`)
  //         this.layout = markRaw(component?.default) || DefaultLayout
  //       } catch(e) {
  //         this.layout = DefaultLayout
  //       }
  //     }
  //   }
  // }
};
</script>
