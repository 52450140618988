import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import { createI18n } from "vue-i18n";
import VCalendar from "v-calendar";
import translationMessages from "../src/assets/files/lang.json";

import AppLayout from "./layouts/AppLayout";

import mitt from "mitt";
const emitter = mitt();

let app = createApp(App);
app.config.globalProperties.emitter = emitter;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// Form Input Labels(Hide/Show)
app.directive("focus", {
  async mounted(el) {
    // el.addEventListener('focus', (e) => {
    //   e.target.classList.add('focused')
    // })
    // el.addEventListener('blur', (e) => {
    //   if( !e.target.value ) e.target.classList.remove('focused')
    // })
    await el.addEventListener("input", (e) => {
      if (e.target.value) {
        e.target.classList.add("focused");
      } else {
        e.target.classList.remove("focused");
      }
    });
    setTimeout(() => {
      if (el.value) {
        el.classList.add("focused");
      }
    }, 1);
    let inter = 1;

    var refreshIntervalId = setInterval(() => {
      inter++;
      if (el.value) {
        el.classList.add("focused");
      }
      if (inter == 10) {
        clearInterval(refreshIntervalId);
      }
    }, 100);
  },
});

const messages = {
  en: {
    message: translationMessages.messages,
  },
  fr: {
    message: translationMessages.messages,
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "fr",
  fallbackLocale: "en",
  messages,
});

store.dispatch("auth/me").then(() => {
  app
    .use(store)
    .use(VCalendar)
    .use(router)
    .use(i18n)
    .component("AppLayout", AppLayout)
    .mount("#app");
});
