<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <AppLayout>
    <router-view :key="router.path" />
  </AppLayout>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  setup() {
    const router = useRoute();
    return { router };
  },
};
</script>
