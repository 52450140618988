import api from "../services/api";
import auth from "./auth";
import helper from "@/helper";
export default {
  namespaced: true,

  state: {},
  mutations: {},
  actions: {
    async fetchEntries(_, payload) {
      let fromDate = helper().filterDate(payload.from_date);
      let toDate = helper().filterDate(payload.to_date);
      let apiUrl = `/journal/entry/fetch`;
      if (payload.apiUrl) {
        apiUrl = payload.apiUrl;
      }
      // console.log(payload.from_date, payload.from_date.toISOString());
      // let formattedDate = `${payload.from_date.toISOString().split("T")[0]} ${
      //   payload.from_date.toISOString().split("T")[1].split(".")[0]
      // }`;
      if (payload.isGroupedBy == undefined) payload.isGroupedBy = false;
      let journals = await api()
        .post(apiUrl + `?isGroupedBy=${payload.isGroupedBy ? "yes" : "no"}`, {
          from_date: fromDate,
          to_date: toDate,
          client_uuid: payload.client_uuid,
          type: payload.type?.toUpperCase() || "",
          ohada_codes: payload.ohada_codes || "",
          operation_type: payload.operation_type || "general",
          sub_journal_name: payload.name,
        })
        .then((res) => {
          return res;
        });
      return journals.data;
    },
    async fetchAllEntries(_, payload) {
      let fromDate = helper().filterDate(payload.from_date);
      let toDate = helper().filterDate(payload.to_date);
      let apiUrl = `/journal/all-entry/fetch`;
      if (payload.apiUrl) {
        apiUrl = payload.apiUrl;
      }
      let allJournals = await api()
        .post(apiUrl, {
          from_date: fromDate,
          to_date: toDate,
          client_uuid: payload.client_uuid,
        })
        .then((res) => {
          return res;
        });
      return allJournals.data;
    },
    async updateRow(_, payload) {
      payload.append("user_id", auth.state.user.id);
      await api()
        .post("/journal/row/udpate", payload)
        .catch(() => {
          alert("Something went wrong! Please try again.");
        });
    },
    async deleteRow(_, payload) {
      console.log(payload);
      let res = await api().post("/journal/row/delete", payload);
      return res;
    },
    async generatePdf(_, payload) {
      let res = await api().post(
        `/pdf/generate?client_id=${payload.client_id}&journal_type=${payload.journal_type}&print_date=${payload.print_date}`,
        {
          ohada_codes: payload.ohada_codes,
          operation_type: payload.operation_type,
          from_date: payload.from_date,
          to_date: payload.to_date,
          sub_journal_name: payload.name,
        }
      );
      return res.data;
    },
    async generateFrontendPdf(_, payload) {
      let res = await api().post(
        `/pdf/generate/frontend?client_id=${payload.client_id}&journal_type=${payload.journal_type}&print_date=${payload.print_date}`,
        {
          operation_type: payload.operation_type,
          frontend_data: payload.frontend_data,
          total_balance: payload.total_balance,
          pdf_label: payload.data,
        }
      );

      return res.data;
    },
  },
};
