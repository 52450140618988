import { getCurrentInstance } from "vue";

export default () => {
  function emitter() {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    return emitter;
  }

  function filterDate(date) {
    let _y = date.getFullYear();
    let _m =
      date.getMonth().toString().length == 1 && date.getMonth() != 9
        ? "0" + (Number(date.getMonth()) + 1)
        : Number(date.getMonth()) + 1;
    let _d =
      date.getDate().toString().length == 1
        ? "0" + date.getDate()
        : date.getDate();
    let _h = date.getHours();
    let _mi = date.getMinutes();
    let _s = date.getSeconds();

    let formattedDate = `${_y}-${_m}-${_d} ${_h}:${_mi}:${_s}`;
    return formattedDate;
  }

  function convertToDateObject(dateString) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let splitedDate = dateString.split("-");
    let dateObject = new Date(
      splitedDate[2] + "-" + splitedDate[1] + "-" + splitedDate[0]
    );
    return dateObject;
  }

  return {
    emitter,
    filterDate,
    convertToDateObject,
  };
};
