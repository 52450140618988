import axios from "axios";
export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    token: null,
  },
  mutations: {
    SET_AUTHENTICATION(state, payload) {
      state.authenticated = payload;
    },
    SET_USER(state, payload) {
      if (payload != null) {
        state.user = payload;
        localStorage.setItem("user", JSON.stringify(payload));
        // axios.defaults.headers.common.Authorization = `Bearer ${ payload.token }`
      }
    },
    SET_TOKEN(state, payload) {
      if (payload != null) state.token = `Bearer ` + payload;
    },
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
  },
  actions: {
    async logIn({ dispatch, commit }, credentials) {
      await axios.get("/sanctum/csrf-cookie");
      let login = await axios
        .post("/api/login", credentials)
        .then((res) => {
          if (res.data.status === "error") {
            return res.data;
          } else {
            let token = res.data.token;
            localStorage.setItem("pr_token", token);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            commit("SET_TOKEN", token);
            return dispatch("me");
          }
        })
        .catch(() => {
          commit("SET_AUTHENTICATION", false);
          commit("SET_USER", null);
          commit("SET_TOKEN", null);
        });
      return login;
    },
    async register(_, payload) {
      let register = await axios.post("/api/register", payload);
      return register.data;
    },
    async logOut({ state, dispatch, commit }) {
      state.authenticated = false;
      localStorage.setItem("pr_token", null);
      commit("SET_AUTHENTICATION", null);
      commit("SET_TOKEN", null);
      axios.defaults.headers.common.Authorization = null;
      return dispatch("me");
    },
    me({ commit }) {
      let pr_token = localStorage.getItem("pr_token") || null;
      axios.defaults.headers.common.Authorization = `Bearer ${pr_token}`;
      commit("SET_TOKEN", pr_token);
      return axios
        .get("/api/user", { Authorization: `Bearer ${pr_token}` })
        .then((response) => {
          commit("SET_AUTHENTICATION", true);
          commit("SET_USER", response.data);
          return "success";
        })
        .catch(() => {
          commit("SET_AUTHENTICATION", false);
          commit("SET_USER", null);
          return "fail";
        });
    },
  },
};
