import api from "../services/api";
import auth from "./auth";
export default {
  namespaced: true,

  state: {
    clients: [],
  },
  mutations: {
    FETCH_CLIENTS(state, payload) {
      state.clients = payload;
    },
    ADD_CLIENT(state, payload) {
      state.clients.unshift(payload);
    },
  },
  actions: {
    async addEditClient({ commit }, payload) {
      let res;
      if (payload.mode === "create")
        res = await api().post("/client/add", payload);
      else if (payload.mode === "edit") {
        payload.parent_id = auth.state.user.id;
        payload.client_id = payload.id;
        res = await api().post("/client/update", payload);
      }
      if (payload.mode === "create" && res.data.status === "success") {
        payload.id = res.data.client_id;
        commit("ADD_CLIENT", payload);
      }
      return res.data;
    },
    async fetchAllClients({ commit }) {
      let parent_id = auth.state.user.id;
      await api()
        .post("/client/get", { parent_id: parent_id, feature: "Client" })
        .then((res) => {
          if (res.data.status === "success") {
            commit("FETCH_CLIENTS", res.data.data);
          }
        })
        .catch((err) => {
          console.log("Store/client fetch clients");
          return err;
        });
    },
    async fetchAllSuppliers({ commit }) {
      let parent_id = auth.state.user.id;
      await api()
        .post("/client/get", { parent_id: parent_id, feature: "Supplier" })
        .then((res) => {
          if (res.data.status === "success") {
            commit("FETCH_CLIENTS", res.data.data);
          }
        })
        .catch((err) => {
          console.log("Store/supplier fetch suppliers");
          return err;
        });
    },
    async fetchAllEmployees({ commit }) {
      let parent_id = auth.state.user.id;
      await api()
        .post("/client/get", { parent_id: parent_id, feature: "Employee" })
        .then((res) => {
          if (res.data.status === "success") {
            commit("FETCH_CLIENTS", res.data.data);
          }
        })
        .catch((err) => {
          console.log("Store/employee fetch employees");
          return err;
        });
    },
    async fetchClientByUUID(_, payload) {
      let client = await api()
        .post("/client/fetch/uuid", {
          parent_id: auth.state.user.id,
          uuid: payload.uuid,
        })
        .then((res) => {
          return res;
        });
      return client.data;
    },
    async archieveClient({ state }, payload) {
      let parent_id = auth.state.user.id;
      let client_id = payload.client_id;
      let index = state.clients.findIndex(
        (client) => client.id === payload.client_id
      );
      state.clients[index].status = "ARCHIVE";
      state.clients = state.clients.filter(
        (client) => client.status === "ACTIVE"
      );
      let res = await api().post("/client/archive", {
        client_id: client_id,
        parent_id: parent_id,
      });
      return res.data;
    },
    async unarchieveClient({ state }, payload) {
      let parent_id = auth.state.user.id;
      let client_id = payload.client_id;
      let index = state.clients.findIndex(
        (client) => client.id === payload.client_id
      );
      console.log(index);
      state.clients[index].status = "ACTIVE";
      state.clients = state.clients.filter(
        (client) => client.status === "ARCHIVED"
      );
      let res = await api().post("/client/unarchive", {
        client_id: client_id,
        parent_id: parent_id,
      });
      return res.data;
    },
    async deleteClient({ state }, client) {
      state.clients = state.clients.filter((cl) => cl.id != client.id);
      let parent_id = auth.state.user.id;
      let res = await api().post("/client/delete", {
        parent_id: parent_id,
        client_id: client.id,
      });
      return res.data;
    },
    async attachSubUser({ state }, payload) {
      let parent_id = auth.state.user.id;
      state.clients.map((c) => {
        if (c.id == payload.file_id) {
          let obj = {
            parent_id,
            client_id: payload.subuser.client_id,
            user_id: payload.subuser.id,
          };
          c.client_subusers.push(obj);
        }
      });
      let res = await api().post("client/action/assign-subuser", {
        parent_id: parent_id,
        client_id: payload.subuser.client_id,
        user_id: payload.subuser.id,
      });
      return res;
    },
    async detachSubUser({ state }, payload) {
      state.clients.forEach((c) => {
        if (c.id == payload.file_id) {
          c.client_subusers = c.client_subusers.filter(
            (c) => c.user_id != payload.subuser.id
          );
        }
      });
      let parent_id = auth.state.user.id;
      let res = await api().post("client/action/detach-subuser", {
        parent_id,
        client_id: payload.subuser.client_id,
        user_id: payload.subuser.id,
      });
      return res;
    },
  },
};
