export default {
  namespaced: true,

  state: {
    notification: {
      show: false,
      messsage: null,
      color: null,
    },
  },
  mutations: {
    SEND_NOTIFICATION(state, payload) {
      if (payload && payload.status === "success") {
        state.notification.show = true;
        state.notification.messsage = payload.message;
        state.notification.color = payload.status;
        setTimeout(() => {
          state.notification.show = false;
          state.notification.messsage = null;
          state.notification.color = null;
        }, 2500);
      } else {
        state.notification.show = false;
        state.notification.messsage = null;
        state.notification.color = null;
      }
    },
  },
};
