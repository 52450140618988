import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
// import Home from "../views/Home.vue";

// import Dashboard from '../views/system/Dashboard.vue';

// import MyClients from "@/views/system/clients/MyClients.vue";

const Landing = () => import("@/views/Landing.vue");
const Dashboard = () => import("@/views/system/Dashboard.vue");
const MyClients = () => import("@/views/system/clients/MyClients.vue");
const Journal = () => import("@/views/system/clients/journal/Index.vue");

const NewJournal = () => import("@/views/system/clients/journal/New.vue");
const ListJournal = () => import("@/views/system/clients/journal/List.vue");

// const LedgerOfThirdOld = () =>
//   import("@/views/system/clients/journal/ledger/LedgerOfThirdOld.vue");
const GeneralLedger = () =>
  import("@/views/system/clients/journal/ledger/GeneralLedger.vue");
const Ledgers = () =>
  import("@/views/system/clients/journal/ledger/Ledgers.vue");

const Balance = () => import("@/views/system/clients/balance/BalanceIndex.vue");

const MyCodes = () => import("@/views/system/codes/MyCodes.vue");
const ManageAccount = () => import("@/views/system/account/ManageAccount.vue");
const MyAccount = () => import("@/views/system/account/Profile.vue");
const BusinessSettings = () =>
  import("@/views/system/account/BusinessSettings.vue");

const system = {
  auth: true,
  layout: "SystemLayout",
};

const guest = {
  guest: true,
  layout: "DefaultLayout",
};

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: {
      ...guest,
      title: "Prohada Landing Page",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { ...system, title: "Prohada - Dashboard" },
  },
  {
    path: "/my-clients",
    name: "MyClients",
    component: MyClients,
    meta: {
      ...system,
      title: "Prohada - My Clients",
    },
  },
  {
    path: "/my-suppliers",
    name: "MySuppliers",
    component: MyClients,
    meta: {
      ...system,
      title: "Prohada - My Suppliers",
    },
  },
  {
    path: "/my-employees",
    name: "MyEmployees",
    component: MyClients,
    meta: {
      ...system,
      title: "Prohada - My Employees",
    },
  },
  {
    path: "/journal",
    name: "Journal",
    component: Journal,
    children: [
      {
        path: ":uuid/list",
        name: "Journal",
        component: ListJournal,
      },
      {
        path: ":uuid/ledger-of-third",
        name: "ThirdOld",
        component: Ledgers,
      },
      {
        path: ":uuid/ledger/third",
        name: "Third",
        component: Ledgers,
      },
      {
        path: ":uuid/ledger/general",
        name: "General",
        component: Ledgers,
      },
      {
        path: ":uuid/new",
        name: "NewJournal",
        component: NewJournal,
        // meta: {
        //   ...system,
        //   title: "Journal",
        // },
      },
      {
        path: "ledger/third",
        name: "LedgerOfThird",
        component: Ledgers,
      },
      {
        path: "ledger/general",
        name: "GeneralLedger",
        component: GeneralLedger,
      },
    ],
    meta: {
      ...system,
      title: "Journal",
    },
  },
  {
    path: "/:uuid/balance",
    name: "Balance",
    component: Balance,
    meta: {
      ...system,
      title: "Prohada - Balance",
    },
  },
  {
    path: "/clients/archived",
    name: "ArchivedClients",
    component: MyClients,
    meta: {
      ...system,
      title: "Prohada - Archived Clients",
    },
  },
  {
    path: "/suppliers/archived",
    name: "ArchivedSuppliers",
    component: MyClients,
    meta: {
      ...system,
      title: "Prohada - Archived Suppliers",
    },
  },
  {
    path: "/employees/archived",
    name: "ArchivedEmployees",
    component: MyClients,
    meta: {
      ...system,
      title: "Prohada - Archived Employees",
    },
  },
  {
    path: "/my-codes",
    name: "MyCodes",
    component: MyCodes,
    meta: {
      ...system,
      title: "Prohada - My Codes",
    },
  },
  // Accounts
  {
    path: "/account/manage",
    name: "ManageAccount",
    component: ManageAccount,
    meta: {
      ...system,
      title: "Prohada - Mange Accounts",
    },
  },
  {
    path: "/account/profile",
    name: "MyAccount",
    component: MyAccount,
    meta: {
      ...system,
      title: "Prohada - My Profile",
    },
  },
  {
    path: "/account/business-settings",
    name: "BusinessSettings",
    component: BusinessSettings,
    meta: {
      ...system,
      title: "Prohada - Business Settings",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  document.title = to.meta.title;
  // Trigger Loader / Loading bar
  store.commit("component/SET_LOADER", true);
  const loggedIn = store.state.auth.authenticated;
  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/");
    return;
  }
  if (to.matched.some((record) => record.meta.guest) && loggedIn) {
    next("/dashboard");
    return;
  }
  next();
});

export default router;
