import api from "../services/api";
// import auth from "./auth";
export default {
  namespaced: true,
  state: {
    messages: [],
  },
  mutations: {
    STORE_MSG(state, payload) {
      state.messages = payload;
    },
  },
  actions: {
    async fetchLang({ commit }) {
      let languages = await api()
        .get("/generate/js/lang.js")
        .then((res) => {
          console.log(res);
          commit("STORE_MSG", res.data);
          return res;
        });
      return languages.data;
    },
  },
};
