export default {
  namespaced: true,

  state: {
    loader: {
      is_loading: false,
    },
  },
  mutations: {
    SET_LOADER(state, payload) {
      state.loader.is_loading = payload;
    },
  },
};
