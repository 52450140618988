import api from "../services/api";
import auth from "./auth";
export default {
  namespaced: true,

  state: {
    accounts: [],
    profile: [],
  },
  mutations: {
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_ALL_ACCOUNTS(state, payload) {
      state.accounts = payload;
    },
    ADD_ACCOUNT(state, payload) {
      state.accounts.unshift(payload);
    },
    UPDATE_ACCOUNT(state, payload) {
      let index_to_update = state.accounts.findIndex(
        (a) => a.id == payload.user_id
      );
      state.accounts[index_to_update] = payload;
    },
    BLOCK_UNBLOCK_ACCOUNT(state, payload) {
      let index = state.accounts.findIndex((a) => a.id === payload.id);
      state.accounts[index].is_blocked = !payload.is_blocked;
    },
  },
  actions: {
    async fetchAllUsersAccount({ commit }) {
      let user_id = auth.state.user.id;
      await api()
        .post("/user/fetch", { parent_id: user_id })
        .then((res) => {
          if (res.data.status === "success") {
            commit("SET_ALL_ACCOUNTS", res.data.data);
          }
        })
        .catch((err) => {
          console.log("Error while fetching the accounts!");
          return err;
        });
    },
    async addEditUser({ commit }, payload) {
      let addAccount;
      if (payload.mode == "create") {
        addAccount = await api()
          .post("/user/add", payload.formData)
          .then((res) => {
            console.log("res form back", res);
            if (res.data.status == "success") {
              commit("ADD_ACCOUNT", payload.formData);
            }
            return res;
          });
      } else if (payload.mode == "edit") {
        addAccount = await api()
          .post("/user/update", payload.formData)
          .then((res) => {
            console.log("update res", res);
            if (res.data.status == "success") {
              commit("UPDATE_ACCOUNT", payload.formData);
            }
            return res;
          });
      }
      // if (addAccount.data.status === "error") {
      //   return addAccount.data;
      // }
      // if (addAccount.data.status === "success" && payload.mode == "create") {
      //   commit("ADD_ACCOUNT", payload.formData);
      // } else {
      //   if (!payload.formData.id && payload.formData.user_id)
      //     payload.formData.id = payload.formData.user_id;
      //   commit("UPDATE_ACCOUNT", payload.formData);
      // }
      return addAccount.data;
    },
    async blockSubUser({ commit }, payload) {
      let parent_id = auth.state.user.id;
      commit("BLOCK_UNBLOCK_ACCOUNT", payload);
      let block = await api().post("/user/block-unblock", {
        parent_id: parent_id,
        user_id: payload.id,
      });
      return block.data;
    },
    async deleteSubUser({ state, commit }, payload) {
      let accounts = state.accounts.filter((a) => a.id !== payload);
      commit("SET_ALL_ACCOUNTS", accounts);
      let parent_id = auth.state.user.id;
      let deleteSubUser = await api().post("/user/delete", {
        parent_id: parent_id,
        user_id: payload,
      });
      return deleteSubUser.data;
    },
    async fetchBusinessSettings() {
      let businessSettings = await api().post("business-settings/get", {
        parent_id: auth.state.user.id,
      });
      return businessSettings.data;
    },
    async updateBusinessSettings(_, payload) {
      payload.parent_id = auth.state.user.id;
      let updateSettings = await api().post(
        "/business-settings/update",
        payload
      );
      return updateSettings.data;
    },
    async fetchProfile({ commit }) {
      let profile = await api().post("user-profile/fetch", {
        user_id: auth.state.user.id,
      });
      if (profile) commit("SET_PROFILE", profile.data);
      return profile.data;
    },
    async updateProfile(_, payload) {
      payload.append("user_id", auth.state.user.id);
      let updateProfile = await api().post("user-profile/update", payload);
      return updateProfile.data;
    },
  },
};
